import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import CallBtn from "./CallBtn";

const SliderWrapper = () => {
    return (
        <article className="slider_wrapper">
            <Carousel fade className="carousel">
                <Carousel.Item className="carousel_item">
                    <Carousel.Caption>
                        <h3 className="title">
                            <span className="clr_yellow">7/24</span> sizin için
                            yoldayız
                        </h3>
                        <p className="subtitle">
                            Her zaman, her yerde, size bir tık uzaklıkta.
                        </p>
                        <CallBtn />
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className="carousel_item">
                    <Carousel.Caption>
                        <h3 className="title">
                            {" "}
                            Seyahatinizi{" "}
                            <span className="clr_yellow">konforlu</span> hale
                            getiriyoruz
                        </h3>
                        <p className="subtitle">
                            Rahatınız için buradayız, hızınız için yoldayız.
                        </p>
                        <CallBtn />
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className="carousel_item">
                    <Carousel.Caption>
                        <h3 className="title">
                            Bize <span className="clr_yellow">güvenin</span>,{" "}
                            <br />
                            zamanında varın.
                        </h3>
                        <p className="subtitle">
                            Sizin güvenliğiniz bizim önceliğimiz.
                        </p>
                        <CallBtn />
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className="carousel_item">
                    <Carousel.Caption>
                        <h3 className="title">
                            <span className="clr_yellow">Anında</span>{" "}
                            kapınızdayız
                        </h3>
                        <p className="subtitle">
                            Bizi arayın, size zaman kazandıralım.
                        </p>
                        <CallBtn />
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </article>
    );
};

export default SliderWrapper;
