/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import SliderWrapper from "../../utils/Slider";
import { FaWhatsapp } from "react-icons/fa";
import { MdLocalPhone } from "react-icons/md";

const Main = () => {
    return (
        <main className="main">
            <section className="slider">
                <SliderWrapper />
            </section>
            <section className="map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1087.2662576984994!2d27.04208428392844!3d39.5764395998964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMznCsDM0JzM1LjQiTiAyN8KwMDInMzQuMiJF!5e0!3m2!1str!2str!4v1711049978631!5m2!1str!2str"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    className="map"
                ></iframe>
            </section>

            <a
                href="https://wa.me/+905528037510?text=Merhaba%20taksi%20çağırmak%20istiyorum."
                class="whatsapp-btn"
                target="_blank"
            >
                <FaWhatsapp />
            </a>

            <a href="tel:+905528037510" class="call-btn-red" target="_blank">
                <MdLocalPhone />
            </a>
        </main>
    );
};

export default Main;
