import React from "react";
import { MdLocalPhone } from "react-icons/md";

const CallBtn = () => {
    return (
        <a href="tel:+905528037510" className="call-btn">
            <span className="btn-number">
                <MdLocalPhone className="btn-icon" />
                0552 803 75 10
            </span>
            <span className="btn-text">Hemen Arayın</span>
        </a>
    );
};

export default CallBtn;
