import React from "react";
import { FaTaxi } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { MdLocalPhone } from "react-icons/md";

const Header = () => {
    return (
        <header className="header">
            <div className="auto-container">
                <div className="main-box">
                    <div className="logo">
                        <FaTaxi className="logo_icon" />
                        <a
                            href="https://edremithastanetaksi.vercel.app/"
                            className="logo_text"
                        >
                            Edremit Hastane Taksi
                        </a>
                    </div>
                    <div className="outher-box">
                        <p className="contact">
                            <span>
                                <IoLocationSharp />
                                <a href="https://maps.app.goo.gl/7NKqxko9BrJdgxKr9">
                                    Cennet Ayağı Mahallesi, 781. Sokak No:7,
                                    10300 Edremit/Balıkesir
                                </a>
                            </span>
                        </p>
                        <span className="phone">
                            <span>
                                <MdLocalPhone />{" "}
                                <a href="tel:+905528037510">
                                    0 (552) 803 75 10
                                </a>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
