import "./App.css";
import Header from "./components/header/Header";
import Main from "./components/main/Main";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

function App() {
    ReactGA.initialize("G-14TKHDS50B");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    return (
        <div className="app">
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta
                    name="description"
                    content="Balıkesir Edremit Hastane Taksi durağımız profesyonel ve güvenilir şekilde en uygun fiyatlarla hizmetinizdeyiz. Acil ve planlı hastane sevklerinizde her zaman yanınızdayız."
                />
                <meta
                    name="keywords"
                    content="Balıkesir Edremit hastane taksi, Acil hasta taşıma Edremit, Güvenilir taksi durağı, Uygun fiyatlı hasta taşıma hizmetleri, Edremit ulaşım çözümleri, Acil hasta nakli, Hızlı taksi durağı, Hastane taşımacılığı, Edremit hastane transferi"
                />
                <meta name="robots" content="index,follow" />
                <meta name="geo.placename" content="Balıkesir Edremit" />
                <meta name="geo.region" content="TR-10" />
                <meta name="language" content="Turkish" />
                <meta name="distribution" content="global" />
                <meta name="expires" content="never" />
                <title>Balıkesir Edremit Hastane Taksi</title>
                <link
                    rel="canonical"
                    href="https://www.edremithastanetaksi.com/"
                />
            </Helmet>

            <Header />
            <Main />
        </div>
    );
}

export default App;
